<template>
  <div
      ref="backToTop"
      class="p-3.5 fixed bottom-5 right-5 z-30 bg-primary rounded-full cursor-pointer border border-white/60"
      style="box-shadow: 0px 13px 12px -5px rgba(255, 128, 80, 0.5)"
  >
    <p class="w-7.5 h-7.5 i-custom-chevron-up text-white"></p>
  </div>
</template>

<script setup lang="ts">
const backToTop = ref<HTMLElement | any>(null)
onMounted(() => {
  window.addEventListener('scroll', () => {
    if (window.scrollY > 50) {
      backToTop.value?.classList.remove('hidden')
      backToTop.value?.classList.add('block')
      backToTop.value?.addEventListener('click', () => {
        document.querySelector('html')?.classList.add('scroll-smooth')
        setTimeout(() => {
          window.scrollTo(0, 0)
          setTimeout(() => {
            document.querySelector('html')?.classList.remove('scroll-smooth')
          }, 1000)
        }, 0)
      })
    } else {
      backToTop.value?.classList.add('hidden')
    }
  })
})
</script>

<style></style>
